<template>
    <div class="form-upload">
        <div class="form-upload-tip" v-if="uploadTip">{{uploadTip}}</div>
        <div class="form-upload-area">
            <a-upload
              :accept="customAccept"
              :list-type="listType"
              :file-list="fileList"
              :remove="removeFile"
              :before-upload="checkFileSize"
              :custom-request="uploadFile"
              v-if="mediaType == 'image'">
                <div v-if="limit == -1 || fileList.length < limit">
                    <slot name="button" v-if="$slots.button"></slot>
                    <a-button v-else-if="listType == 'text'">
                        <a-icon type="upload" />
                        文件上传
                    </a-button>
                    <template v-else>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">点击上传</div>
                    </template>
                </div>
            </a-upload>
            <div class="media-upload" v-else-if="mediaType == 'audio'">
                <input class="file-input" type="file" accept="audio/*" style="display:none;" @change="fileSelect">
                <a-button @click="uploadClick">
                    <a-icon type="upload" />
                    <span>点击上传</span>
                </a-button>
            </div>
            <div class="media-upload" v-else-if="mediaType == 'video'">
                <input class="file-input" type="file" accept="video/*" style="display:none;" @change="fileSelect">
                <a-button @click="uploadClick">
                    <a-icon type="upload" />
                    <span>点击上传</span>
                </a-button>
            </div>
            <div class="media-upload" v-else-if="mediaType == 'mix'">
                <input class="file-input" type="file" :accept="accept" style="display:none;" @change="fileSelect">
                <a-dropdown :trigger="['click']">
                    <a-button>
                        <a-icon type="upload" />
                        <span>点击上传</span>
                    </a-button>
                    <a-menu slot="overlay" @click="handleFileMenuClick">
                        <a-menu-item key="image">图片</a-menu-item>
                        <a-menu-divider />
                        <a-menu-item key="video">视频</a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
            <div class="media-list" v-show="fileList && fileList.length > 0" v-if="mediaType != 'image'">
                <div class="media-item" :class="{error: item.status == 'error'}" v-for="(item, i) in fileList" :key="i">
                    <div class="media-preview">
                        <template v-if="item.status == 'uploading'">
                            <a-progress type="circle" :stroke-width="4" :width="64" class="media-progress" :percent="item.percent" />
                            <div class="media-title">正在上传</div>
                        </template>
                        <template v-else-if="item.status == 'error'">
                            <a-icon type="exclamation-circle" :style="{color: '#f5222d', fontSize: '32px'}" />
                            <div class="media-title">上传失败</div>
                        </template>
                        <template v-else-if="item.status == 'done'">
                            <img :src="item.url" class="image-preview" v-if="item.type == 'image'"/>
                            <audio
                              :src="item.url"
                              controls
                              class="audio-preview"
                              @loadedmetadata="$emit('duration', $event.target.duration)"
                              v-else-if="item.type == 'audio' || mediaType == 'audio'"></audio>
                            <video
                              controls
                              :src="item.url"
                              :poster="isUrl(item.poster) ? item.poster : null"
                              class="video-preview"
                              @loadedmetadata="$emit('duration', $event.target.duration)"
                              v-else-if="item.type == 'video' || mediaType == 'video'"></video>
                        </template>
                        <div class="cover-upload" v-if="item.poster === 'uploading'">
                            <a-progress type="circle" :stroke-width="4" :width="64" class="media-progress" :percent="item.percent" />
                            <div class="media-title">正在上传封面</div>
                        </div>
                    </div>
                    <div class="media-operate">
                        <div class="txt-btn danger" @click="removeSelectFile(i)">删除</div>
                        <template v-if="mediaType == 'mix' && item.type == 'video'">
                            <a-divider type="vertical" />
                            <div class="txt-btn" @click="addPoster(i)">添加封面</div>
                        </template>
                        <template v-else-if="mediaType == 'mix' && item.type == 'image'">
                            <a-divider type="vertical" />
                            <div class="txt-btn" @click="previewImage(item)">预览</div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    import {beforeUpload} from "../common/js/upload";
    import {directUploadTencentCloud} from "../common/js/tencent_cloud";
    import {getUrlFileName, isUrl, isArray, clone} from "../common/js/tool";

    export default {
        name: "form-upload",
        model: {
            event: 'change'
        },
        props: {
            listType: {
                type: String,
                default: 'picture-card'
            },
            // -1无限制
            limit: {
                type: Number,
                default: 1
            },
            //图片上传大小限制
            size: {
                type: Number,
                default: 10 * 1024 * 1
            },
            //支持string和array 及用多个链接传入string 可以以,隔开
            value: [String, Array],
            mediaType: {
                type: String,
                default: 'image'
            },
            saveFileName: Boolean, // 是否存储文件名
            uploadTip: String,
            customAccept: {
                type: String,
                default: "image/*"
            },
            folderPrefix: String,
        },
        data() {
            return {
                fileList: [],
                type: 'image',
                index: null
            }
        },
        computed: {
            accept() {
                let type = this.type;
                if(type == 'image') {
                    return 'image/*'
                } else if(type == 'video') {
                    return 'video/*';
                } else {
                    return "*/*"
                }
            }
        },
        watch: {
            value() {
                this.setFileList();
            }
        },
        created() {
            this.setFileList();
        },
        methods: {
            isUrl,
            addPoster(i) {
                this.index = i;
                this.type = 'image';
                this.$nextTick(this.uploadClick);
            },
            previewImage(item){
                window.open(item.url, '_blank');
            },
            handleFileMenuClick(e) {
                let key = e.key;
                this.index = null;
                this.type = key;
                this.$nextTick(this.uploadClick);
            },
            uploadClick() {
                if(this.fileList.length >= this.limit) {
                    this.$message.info(`最多只能添加${this.limit}个文件`)
                    return;
                }
                let input = this.$el.querySelector(".file-input");
                input.click();
            },
            fileSelect(e) {
                let file = e.target.files[0];
                let index = this.index;
                let item;
                if(index === null) {
                    item = {
                        name: file.name,
                        status: 'uploading',
                        url: null,
                        percent: 0
                    }
                    if(this.mediaType == 'mix') {
                        item.type = this.type;
                    }
                    this.fileList.push(item);
                } else {
                    item = this.fileList[index];
                    this.$set(item, 'poster', 'uploading');
                }
                directUploadTencentCloud(file, p => {
                    this.$set(item, 'percent', Math.round(p.percent * 100));
                }, this.folderPrefix).then(data => {
                    let path = "https://" + data.Location;
                    this.$set(item, 'status' , 'done');
                    if(item.poster == 'uploading') {
                        this.$set(item, 'poster' , path);
                    } else {
                        this.$set(item, 'url' , path);
                    }
                    this.dispatchInput();
                    this.clearInput();
                }).catch(() => {
                    this.$set(item, 'status', 'error');
                    this.clearInput();
                })
            },
            clearInput() {
                let input = this.$el.querySelector(".file-input");
                input.value = '';
            },
            removeSelectFile(index) {
                this.fileList.splice(index, 1);
                this.dispatchInput();
            },
            checkFileSize(file) {
                return beforeUpload(file, this.size);
            },
            removeFile(file) {
                let list = this.fileList;
                let index = list.findIndex(item => item.uid == file.uid)
                if(index >= 0) {
                    list.splice(index, 1);
                }
                this.dispatchInput();
                return true;
            },
            uploadFile(arg) {
                let file = arg.file;
                let list = this.fileList;
                let item = {
                    uid:  -1 * list.length - 1 + '',
                    name: file.name,
                    status: 'uploading',
                    url: null,
                }
                list.push(item);
                directUploadTencentCloud(file, p => {
                    this.$set(item, 'percent', Math.round(p.percent * 100));
                }, this.folderPrefix).then(data => {
                    let path = "https://" + data.Location;
                    this.$set(item, 'status' , 'done');
                    this.$set(item, 'url' , path);
                    this.dispatchInput();
                    this.clearInput();
                }).catch(() => {
                    this.$set(item, 'status', 'error');
                })
                // uploadImg({file}).then(url => {
                //     this.$set(item, 'status' , 'done');
                //     this.$set(item, 'url' , url);
                //     this.dispatchInput()
                // }).catch(() => {
                //     this.$set(item, 'status' , 'error');
                // })
            },
            dispatchInput() {
                let list = this.fileList;
                let temp = [];
                list.forEach(item => {
                    if(item.url) {
                        if(this.mediaType == 'mix') {
                            let file = {url: item.url, type: item.type};
                            if(item.poster) {
                                file.poster = item.poster;
                            }
                            temp.push(file);
                        } else if(this.saveFileName) {
                            temp.push({url: item.url, name: item.name});
                        } else {
                            temp.push(item.url);
                        }
                    }
                });
                if(this.mediaType == 'mix') {
                    this.$emit('change', temp.length > 0 ? JSON.stringify(temp) : null);
                } else if(this.saveFileName) {
                    this.$emit('change', temp.length > 0 ? [...temp] : null);
                } else {
                    this.$emit('change', temp.length > 0 ? temp.join(',') : null);
                }
            },
            setFileList() {
                let value = this.value;
                if(value) {
                    if(isArray(value)) {
                        value = clone(value);
                    } else {
                        if(this.mediaType == 'mix') {
                            try {
                                value = JSON.parse(value);
                            } catch (e) {
                                console.error(e);
                                value = [];
                            }
                        } else {
                            value = value.split(',');
                        }
                    }
                    let list = [], len = value.length;
                    len = len > this.limit && this.limit > 0 ? this.limit : len;
                    for(let i = 0; i < len; i ++) {
                        let item = value[i];
                        let temp = {
                            uid:  -1 * i - 1,
                            status: 'done',
                        }
                        if(typeof item === 'object') {
                            temp.name = item.name || getUrlFileName(item.url);
                            temp.type = item.type;
                            temp.url = item.url;
                            item.poster && (temp.poster = item.poster);
                        } else {
                            temp.name = getUrlFileName(item);
                            temp.url = item;
                        }
                        list.push(temp);
                    }
                    this.fileList = list;
                } else {
                    this.fileList = [];
                }
            }
        }
    }
</script>

<style lang="less">
    .media-list {
        margin-top: 10px;
    }
    .media-item {
        display: inline-block;
        margin: 0 10px 10px 0;
        min-width: 240px;
        border: var(--border);
        border-radius: @border-radius-base;
        &.error {
            border-color: @error-color;
            color: @error-color;
        }
    }
    .media-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: @padding-xs;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        video {
            width: 240px;
            height: 135px;
        }
    }
    .cover-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.4);
    }
    .media-title {
        margin-top: @padding-sm;
        line-height: 1;
    }
    .media-operate {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        border-top: var(--border);
        line-height: 1;
        .txt-btn {
            flex: 1;
            text-align: center;
        }
    }
    .audio-preview {
        margin-right: @padding-sm;
        height: 32px;
    }
    .image-preview {
        width: 240px;
        height: 135px;
        object-fit: cover;
    }
    .file-select {
        margin-top: 16px;
    }
    .form-upload-tip {
        color: @text-color-secondary;
        margin-bottom: 10px;
    }
</style>
