const reception = {
    id: 'reception',
    router: {
        path: 'reception',
        name: 'Reception',
        component: () => import(/* webpackChunkName: "rec" */ '../../views/rec/Reception.vue'),
        meta: {
            title: '接待系统'
        }
    },
}

const recActivity = {
    id: 'recActivity',
    router: {
        path: '/rec/act',
        name: 'RecActivity',
        component: () => import(/* webpackChunkName: "ra" */ '../../views/rec/RecActivity.vue'),
        meta: {
            title: '活动管理'
        }
    },
}

const recManuscript = {
    id: 'recManuscript',
    router: {
        path: '/rec/manu/:id',
        name: 'RecManuscript',
        component: () => import(/* webpackChunkName: "rm" */ '../../views/rec/RecManuscript.vue'),
        meta: {
            title: '投稿详情'
        }
    },
}

const recWish = {
    id: 'recWish',
    router: {
        path: '/rec/wish',
        name: 'RecWish',
        component: () => import(/* webpackChunkName: "rw" */ '../../views/rec/RecWish.vue'),
        meta: {
            title: '祈福管理'
        }
    },
}

const recComplaint = {
    id: 'recComplaint',
    router: {
        path: '/rec/complaint',
        name: 'RecComplaint',
        component: () => import(/* webpackChunkName: "complaint" */ '../../views/rec/RecComplaint.vue'),
        meta: {
            title: '投诉管理'
        }
    },
}

const complaintDetail = {
    id: 'complaintDetail',
    router: {
        path: '/rec/complaint/:id',
        name: 'ComplaintDetail',
        component: () => import(/* webpackChunkName: "complaint" */ '../../views/rec/ComplaintDetail.vue'),
        meta: {
            title: '投诉详情'
        }
    },
}

const recAdmin =  {
    id: 'recAdmin',
    router: {
        path: '/rec/admin',
        name: 'RecAdmin',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/RecAdmin.vue'),
        meta: {
            title: '权限设置'
        }
    },
}

const banner = {id: 'banner', entity: 'banner'};
const article = {id: 'article', entity: 'article'};

const impression = {
    id: 'impression',
    router: {
        path: '/rec/impression',
        name: 'RecImpression',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/CultureImpression.vue'),
        meta: {
            title: '云林印象'
        }
    },
}

const impressionAudit = {
    id: 'impressionAudit',
    router: {
        path: '/rec/image-audit',
        name: 'ImpressionAudit',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/CultureImpression.vue'),
        meta: {
            title: '云林印象'
        }
    },
}

const bless = {
    id: 'bless',
    router: {
        path: '/rec/bless-list',
        name: 'CultureBless',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/CultureBless.vue'),
        meta: {
            title: '心愿列表'
        }
    },
}

const blessPalace = {id: 'blessPalace', entity: 'blessPalace'};
const cultureNotice = {id: 'cultureNotice', entity: 'cultureNotice'};
const introduce = {id: 'introduce', entity: 'introduce'};
const guide = {id: 'guide', entity: 'guide'};

const culturePicture = {
    id: 'culturePicture',
    router: {
        path: '/rec/material-picture',
        name: 'CulturePicture',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/CultureMaterial.vue'),
        meta: {
            title: '图片管理'
        }
    },
}

const cultureVideo = {
    id: 'cultureVideo',
    router: {
        path: '/rec/material-video',
        name: 'CultureVideo',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/CultureMaterial.vue'),
        meta: {
            title: '视频管理'
        }
    },
}

const buddhaReply = {
    id: 'buddhaReply',
    router: {
        path: '/rec/buddha-replay',
        name: 'BuddhaReply',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/rec/BuddhaReply.vue'),
        meta: {
            title: '问答回复'
        }
    },
}

const contributeList = {
    id: 'contributeList',
    router: {
        path: '/rec/contribute-list',
        name: 'ContributeList',
        component: () => import(/* webpackChunkName: "contribute-list" */ '../../views/rec/contribute/ContributeList'),
        meta: {
            title: '分享列表'
        }
    },
}

const contributeAudit = {
    id: 'contributeAudit',
    router: {
        path: '/rec/contribute-audit',
        name: 'ContributeAudit',
        component: () => import(/* webpackChunkName: "contribute-audit" */ '../../views/rec/contribute/ContributeAudit'),
        meta: {
            title: '投稿审核'
        }
    },
}

export default {
    reception,
    recActivity,
    recManuscript,
    recWish,
    recComplaint,
    complaintDetail,
    recAdmin,
    banner,
    article,
    guide,
    impression,
    impressionAudit,
    bless,
    blessPalace,
    cultureNotice,
    culturePicture,
    cultureVideo,
    introduce,
    buddhaReply,
    contributeList,
    contributeAudit,
}

export const routers = [
    {
        type: 1,
        list: [
            {id: 'reception', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'temp', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recActivity', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recManuscript', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recWish', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recComplaint', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'complaintDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recAdmin', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'banner', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'article', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'impression', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'guide', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'impressionAudit', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'bless', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'blessPalace', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'cultureNotice', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'culturePicture', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'cultureVideo', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'introduce', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaReply', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'contributeList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'contributeAudit', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    },
    {
        type: 2,
        list: [
            {id: 'reception', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'temp', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    },
    {
        type: 3,
        list: [
            {id: 'recActivity', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recManuscript', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recWish', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'recComplaint', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'complaintDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
]

export const menus = [
    {
        type: 1,
        list: [
            {
                title: '横幅管理',
                path: '/entity/banner',
            },
            {
                title: '文章管理',
                path: '/entity/article',
            },
            {
                title: '云林印象',
                path: '/rec/impress',
                children: [
                    {
                        title: '图片发布',
                        path: '/rec/impression',
                    },
                    {
                        title: '图片审核',
                        path: '/rec/image-audit',
                    }
                ]
            },
            {
                title: '心愿祈福',
                path: '/rec/bless',
                children: [
                    {
                        title: '心愿列表',
                        path: '/rec/bless-list',
                    },
                    {
                        title: '殿堂管理',
                        path: '/entity/blessPalace',
                    }
                ]
            },
            {
                title: '问答回复',
                path: '/rec/buddha-replay',
            },
            {
                title: '图片管理',
                path: '/rec/material-picture',
            },
            {
                title: '视频管理',
                path: '/rec/material-video',
            },
            {
                title: '公告管理',
                path: '/entity/cultureNotice',
            },
            {
                title: '寺院介绍',
                path: '/entity/introduce',
            },
            {
                title: '用户分享',
                path: '/rec/contribute',
                children: [
                    {
                        title: '分享列表',
                        path: '/rec/contribute-list',
                    },
                    {
                        title: '投稿审核',
                        path: '/rec/contribute-audit',
                    }
                ]
            },
            {
                title: '导览讲解',
                path: '/entity/guide',
            },
            {
                title: '活动管理',
                path: '/rec/act',
            },
            {
                title: '祈福管理',
                path: '/rec/wish',
            },
            {
                title: '投诉管理',
                path: '/rec/complaint',
            },
            {
                title: '权限设置',
                path: '/rec/admin',
            },
        ]
    },
    {
        type: 2,
        list: [
            {
                title: '数据统计',
                path: '/temp/rec/data',
                icon: 'table'
            },
            {
                title: '模板设置',
                path: '/temp/rec/form',
                icon: 'profile'
            },
            {
                title: '规则权限',
                path: '/temp/rec/setting',
                icon: 'setting'
            },
        ]
    },
    {
        type: 3,
        list: [
            {
                title: '活动管理',
                path: '/rec/act',
            },
            {
                title: '祈福管理',
                path: '/rec/wish',
            },
            {
                title: '投诉管理',
                path: '/rec/complaint',
            }
        ]
    }
]
